import React, { useState, useEffect } from 'react';
import UserScatterPlot from './UserScatterPlot';
import { Link } from 'react-router-dom'; // Import the Link component
import LatestTweet from './LatestTweet'; // Import the new component
import UserMatrix from './UserMatrix';
import { Container, LeftContainer, RightContainer, Tab, TweetList, Input, Button, SwappedLeftContainer, SwappedRightContainer } from './CommonStyles';
import { apiUrl } from './Config';

function Users({ setCurrentPage, isGraphVisible, SetGraphVisible, swap, logout }) {
    const [users, setUsers] = useState([]);
    const [tweets, setTweets] = useState([]); // New state for tweets
    const [activeTab, setActiveTab] = useState("scatter");
    const [selectedUserIds, setSelectedUserIds] = React.useState([]);
    const [userCategoriesData, setUserCategoriesData] = useState([]);
    const [username, setUserName] = useState("");
    const [reloadData, setReloadData] = useState(false);

    const hostname = window.location.hostname;

    const token = localStorage.getItem('jwt_token');

    const fetchUserWithScores = async () => {
        try {
            const response = await fetch(`${apiUrl}/api/users_with_scores`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`  // Set the JWT token here
                }

            });

            if (!response.ok) {
                throw new Error(`${response.status} ${response.statusText}`);
            }

            const data = await response.json();
            setUsers(data);
        }
        catch (error) {
            console.error(error);
            logout();
        }

    };

    const fetchLatestTweets = async () => {
        try {
            const response = await fetch(`${apiUrl}/api/latest_tweets`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`  // Set the JWT token here
                }
            });

            if (!response.ok) {
                throw new Error(`${response.status} ${response.statusText}`);
            }

            const data = await response.json();
            setTweets(data);
        }
        catch (error) {
            console.error(error);
            logout();
        }
    };

    const fetchUserCategories = async () => {
        try {
            const response = await fetch(`${apiUrl}/api/users_with_categories`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`  // Set the JWT token here
                }

            });

            if (!response.ok) {
                throw new Error(`${response.status} ${response.statusText}`);
            }

            const data = await response.json();
            setUserCategoriesData(data);
        }
        catch (error) {
            console.error(error);
            logout();
        }
    };

    const searchUser = async () => {
        const encodedKeyword = encodeURIComponent(username);
        try {
            const response = await fetch(`${apiUrl}/api/search_user/${encodedKeyword}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`  // Set the JWT token here
                }

            });

            if (!response.ok) {
                throw new Error(`${response.status} ${response.statusText}`);
            }

            const data = await response.json();

            // イベントデータを作成
            const event = {
                timestamp: new Date().toISOString(),
                type: "search_user",
                where: "search_box",
                id: "",
                name: username,
            };

            // 既存のイベントデータを取得
            const existingEvents = JSON.parse(localStorage.getItem("events") || "[]");
            // 新しいイベントを追加
            existingEvents.push(event);
            // 更新されたデータをローカルストレージに保存
            localStorage.setItem("events", JSON.stringify(existingEvents));

            setReloadData(!reloadData);
        }
        catch (error) {
            console.error(error);
        }
    }

    const handleUserClick = (id, where) => {
        setSelectedUserIds(prevIds => {
            if (prevIds.includes(id)) {
                return [...prevIds.filter(existingId => existingId !== id), id];
            }
            if (prevIds.length >= 5) {
                return [...prevIds.slice(1), id];
            }
            return [...prevIds, id];
        });

        const found_user = users.find(user => user.user_id === id);

        // イベントデータを作成
        const event = {
            timestamp: new Date().toISOString(),
            type: "user_click",
            where: where,
            id: id,
            negative: found_user.score.negative,
            subjective: found_user.score.subjective,
        };

        // 既存のイベントデータを取得
        const existingEvents = JSON.parse(localStorage.getItem("events") || "[]");
        // 新しいイベントを追加
        existingEvents.push(event);
        // 更新されたデータをローカルストレージに保存
        localStorage.setItem("events", JSON.stringify(existingEvents));
    };

    useEffect(() => {
        fetchUserWithScores();
        fetchLatestTweets();
        fetchUserCategories();
    }, [reloadData]);

    return (
        <div>
            <h1><button onClick={() => setCurrentPage('topics')} className='light-link light-link-button' >Topics</button> / Users</h1>
            <Container>
                {swap ? (
                    <>
                        <SwappedRightContainer style={{ display: isGraphVisible ? 'block' : 'none' }}>
                            <Tab active={activeTab === "scatter"} onClick={() => setActiveTab("scatter")}>
                                Pos/Neg
                            </Tab>
                            <Tab active={activeTab === "matrix"} onClick={() => setActiveTab("matrix")}>
                                Category
                            </Tab>
                            <ul>
                                {activeTab === "scatter" && <UserScatterPlot users={users} handleUserClick={handleUserClick} selectedUserIds={selectedUserIds} />}
                                {activeTab === "matrix" && <UserMatrix data={userCategoriesData} handleUserClick={handleUserClick} selectedUserIds={selectedUserIds} />}
                            </ul>
                        </SwappedRightContainer>
                        <SwappedLeftContainer>
                            <div style={{ margin: "8px" }}>
                                <Input type="text" value={username} onChange={event => setUserName(event.target.value)} />
                                <Button onClick={searchUser}>Search user</Button>
                            </div>
                            <TweetList>
                                {tweets.map(tweet => (
                                    <li key={tweet.id} className="tweets-list">
                                        <LatestTweet tweet={tweet} handleUserClick={handleUserClick} selectedUserIds={selectedUserIds} />
                                    </li>
                                ))}
                            </TweetList>
                        </SwappedLeftContainer>
                    </>
                ) : (
                    <>
                        <LeftContainer>
                            <div style={{ margin: "8px" }}>
                                <Input type="text" value={username} onChange={event => setUserName(event.target.value)} />
                                <Button onClick={searchUser}>Search user</Button>
                            </div>
                            <TweetList>
                                {tweets.map(tweet => (
                                    <li key={tweet.id} className="tweets-list">
                                        <LatestTweet tweet={tweet} handleUserClick={handleUserClick} selectedUserIds={selectedUserIds} />
                                    </li>
                                ))}
                            </TweetList>
                        </LeftContainer>
                        <RightContainer style={{ display: isGraphVisible ? 'block' : 'none' }}>
                            <Tab active={activeTab === "scatter"} onClick={() => setActiveTab("scatter")}>
                                Pos/Neg
                            </Tab>
                            <Tab active={activeTab === "matrix"} onClick={() => setActiveTab("matrix")}>
                                Category
                            </Tab>
                            <ul>
                                {activeTab === "scatter" && <UserScatterPlot users={users} handleUserClick={handleUserClick} selectedUserIds={selectedUserIds} />}
                                {activeTab === "matrix" && <UserMatrix data={userCategoriesData} handleUserClick={handleUserClick} selectedUserIds={selectedUserIds} />}
                            </ul>
                        </RightContainer>
                    </>
                )}
            </Container>
        </div>
    );
}

export default Users;
