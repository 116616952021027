import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    height: 100vh;  /* Ensure the container takes full viewport height */
`;

export const TopContainer = styled.div`
    height: 128px;
    overflow-y: auto;
    margin-bottom: 20px;
`;

export const BottomContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const LeftContainer = styled.div.attrs({
  className: 'users-left'
})`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-right: 20px;
  height: calc(100vh - 240px);
  width: 50%;  // 50% of the parent container's width
  z-index: 2;
  scroll-behavior: smooth;
`;

export const RightContainer = styled.div.attrs({
  className: 'users-right'
})`
  width: 50%;  // 50% of the parent container's width
  position: fixed;
  left: 50%;  // Start from the midpoint of the viewport
  overflow-y: auto;
  max-height: calc(100vh - 60px);
  height: 100vh;
  padding-left: 20px;
  scroll-behavior: smooth;
  z-index: 1;
`;

// SwappedLeftContainerの定義。主にRightContainerのスタイル属性を継承。
export const SwappedLeftContainer = styled.div.attrs({
  className: 'users-left'
})`
  width: 50%;  // 50% of the parent container's width
  position: fixed;
  left: 50%;  // Start from the midpoint of the viewport
  overflow-y: auto;
  max-height: calc(100vh - 60px);
  height: 100vh;
  padding-left: 20px;
  scroll-behavior: smooth;
  z-index: 1;
`;

// SwappedRightContainerの定義。主にLeftContainerのスタイル属性を継承。
export const SwappedRightContainer = styled.div.attrs({
  className: 'users-right'
})`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-right: 20px;
  height: calc(100vh - 240px);
  width: 50%;  // 50% of the parent container's width
  z-index: 2;
  scroll-behavior: smooth;
`;

export const Input = styled.input`
  padding: 10px;
  font-size: 16px;
  border-radius: 4px;
  border: 1px solid #ddd;
  margin-right: 10px;
`;

export const Button = styled.button`
  padding: 10px 20px;
  font-size: 16px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }
`;

export const Tab = styled.button`
  padding: 10px;
  margin: 10px;
  cursor: pointer;
  background-color: ${({ active }) => (active ? '#007bff' : 'transparent')};
  color: ${({ active }) => (active ? '#fff' : '#000')};
  border: none;
`;

export const TweetList = styled.div.attrs({
  className: 'tweet-list'
})`
  overflow-y: auto;
  height: calc(85vh - 60px);  // 100vh (全体) - 15vh (TrendList) = 85vh
  list-style-type: none;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
`;

export const Footer = styled.div`
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: #f8f9fa;
    padding: 10px 0;
    text-align: center;
    box-shadow: 0px -2px 5px rgba(0, 0, 0, 0.1);
    z-index: 1000;
`;

export const TrendListContainer = styled.div.attrs({
  className: 'trend-list-container'
})`
  position: static;
  top: 72px;
  overflow-y: auto;
  margin-bottom: 10px;
  padding-bottom: 10px;
  max-height: calc(15vh);  // Change this to adjust the height
`;

// Additional styled components for the login page
export const LoginContainer = styled(Container)`
    align-items: center;
    justify-content: center;
`;

export const LoginForm = styled.form`
    display: flex;
    flex-direction: column;
    width: 300px;
    gap: 20px;  // Spacing between form elements
`;
