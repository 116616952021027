import React, { useState } from 'react';
import styled from "styled-components";
import { TrendListContainer } from './CommonStyles';

const TrendItem = styled.div`
  cursor: pointer;
`;

const TrendText = styled.span`
`;

const TrendDate = styled.span`
  font-size: 0.8em;
`;

function TrendList({ onTrendClick, trends, selectedTrendId }) {
  const handleItemClick = (id) => {
    onTrendClick(id);
  };

  return (
    <TrendListContainer>
      {trends.map((trend, index) => (
        <TrendItem key={trend.id} onClick={() => handleItemClick(trend.id)}
          className={trend.id === selectedTrendId ? 'selected' : 'non-selected'}
        >
          <TrendText>{trend.trend}</TrendText>
        </TrendItem>
      ))}
    </TrendListContainer>
  );
}

export default TrendList;
