import React, { useRef } from 'react';
import { interpolate } from 'd3-interpolate';
import { COLOR_START, COLOR_END, STROKE_COLOR_START, STROKE_COLOR_END } from './Colors';
import { apiUrl } from './Config';

function LatestTweet({ tweet, handleUserClick, selectedUserIds }) {
    const timeoutId = useRef(null);

    const token = localStorage.getItem('jwt_token');

    const fetchOEmbed = async () => {
        const url = `${apiUrl}/api/oembed?url=https://twitter.com/${tweet.screen_name}/status/${tweet.tweet_id}`;
        try {
            const response = await fetch(url);
            if (!response.ok) {
                throw new Error(`${response.status} ${response.statusText}`);
            }

            const data = await response.json();
            return data.html;
        }
        catch (error) {
            console.error(error);
        }
    };

    const [html, setHtml] = React.useState("");

    const colorInterpolator = interpolate(COLOR_START, COLOR_END);

    const handleMouseOver = () => {
        if (timeoutId.current) {
            clearTimeout(timeoutId.current);
        }
        timeoutId.current = setTimeout(() => {
            handleUserClick(tweet.user_id, "timeline");
        }, 1000);
    };

    const handleMouseOut = () => {
        if (timeoutId.current) {
            clearTimeout(timeoutId.current);
        }
    };

    const index = selectedUserIds.indexOf(tweet.user_id);
    let backgroundColor = "transparent"; // Default color set to transparent
    if (index !== -1) {
        const t = selectedUserIds.length > 1 ? 1 - (index / (selectedUserIds.length - 1)) : 0;
        backgroundColor = colorInterpolator(t);
    }

    React.useEffect(() => {
        fetchOEmbed().then((html) => {
            setHtml(html);

            // Twitterのwidgetスクリプトを動的にロード
            const script = document.createElement('script');
            script.src = "https://platform.twitter.com/widgets.js";
            script.async = true;
            document.body.appendChild(script);
        });
    }, []);

    return (
        // Add an id attribute to the outer div
        <div className="latest-tweet" id={`tweet-${tweet.user_id}`}>
            <div style={{ display: 'inline-block', padding: '5px', backgroundColor: backgroundColor }}>
                <div
                    dangerouslySetInnerHTML={{ __html: html }}
                    onMouseOver={handleMouseOver}
                    onMouseOut={handleMouseOut}
                />
            </div>
        </div >
    );
}

export default LatestTweet;
