import React, { useState } from 'react';
import { LoginContainer, LoginForm, Input, Button } from './CommonStyles';  // Importing from CommonStyles.js
import { apiUrl } from './Config';

function Login({ onLoginSuccess }) {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const handleSubmit = async (event) => {
        event.preventDefault();

        console.log("env", process.env.REACT_APP_API_URL);
        console.log('Logging in...', apiUrl);

        try {
            const hostname = window.location.hostname;
            const response = await fetch(`${apiUrl}/api/login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    username: username,
                    password: password
                })
            });

            if (!response.ok) {
                throw new Error('Failed to login');
            }

            const data = await response.json();
            const token = data.access_token;

            if (token) {
                localStorage.setItem('jwt_token', token);
                onLoginSuccess();  // This will notify the App component that login was successful
            } else {
                console.error('No token received');
            }
        }
        catch (error) {
            console.error('Login error:', error);
            // Optionally, you can set some state here to notify the user about the error
        }
    };

    return (
        <LoginContainer>
            <LoginForm onSubmit={handleSubmit}>
                <Input
                    type="text"
                    placeholder="Username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                />
                <Input
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
                <Button type="submit">Login</Button>
            </LoginForm>
        </LoginContainer>
    );
}

export default Login;
