import React, { useRef, useEffect, useState } from 'react';
import * as d3 from 'd3';
import d3Tip from 'd3-tip';
import { interpolate } from 'd3-interpolate';
import { COLOR_START, COLOR_END, STROKE_COLOR_START, STROKE_COLOR_END } from './Colors';

const UserScatterPlot = ({ users, handleUserClick, selectedUserIds }) => {
    const svgRef = useRef(null);
    const containerRef = useRef(null);
    const colorInterpolator = interpolate(COLOR_START, COLOR_END);
    const strokeColorInterpolator = interpolate(STROKE_COLOR_START, STROKE_COLOR_END);

    // コンポーネントのサイズに基づいてwidthとheightの初期状態を設定
    const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

    const updateDimensions = () => {
        if (containerRef.current) {
            const width = containerRef.current.offsetWidth;
            const height = containerRef.current.offsetHeight;
            setDimensions({ width, height });
        }
    };

    useEffect(() => {
        updateDimensions(); // 初期サイズを設定
        window.addEventListener("resize", updateDimensions); // ウィンドウのリサイズをリッスン
        return () => {
            window.removeEventListener("resize", updateDimensions); // クリーンアップ
        };
    }, []);

    useEffect(() => {
        const data = users.map(user => ({
            user_id: user.user_id,
            x: user.score.negative,
            y: user.score.subjective,
            followers_count: user.followers_count,
            follows_count: user.follows_count,
            screen_name: user.screen_name,
            name: user.name
        }));

        const margin = { top: 20, right: 60, bottom: 20, left: 20 };
        const size = Math.min(dimensions.width, window.innerHeight - 240);
        const width = size - margin.left - margin.right;
        const height = size - margin.top - margin.bottom;

        const svg = d3.select(svgRef.current)
            .attr("width", size)
            .attr("height", size);
        svg.selectAll("*").remove();

        const x = d3.scaleLinear().domain([-10, 10]).range([margin.left, size - margin.right]);
        const y = d3.scaleLinear().domain([-10, 10]).range([size - margin.bottom, margin.top]);

        svg.append("text").attr("x", 0).attr("y", height / 2 - 12).attr("text-anchor", "start").style("fill", "black").text("Negative");
        svg.append("text").attr("x", width + 32).attr("y", height / 2 - 12).attr("text-anchor", "end").style("fill", "black").text("Positive");
        svg.append("text").attr("x", width / 2).attr("y", 12).attr("text-anchor", "middle").style("fill", "black").text("Subjective");
        svg.append("text").attr("x", width / 2).attr("y", size - margin.bottom + 18).attr("text-anchor", "middle").style("fill", "black").text("Objective");

        const xAxis = g => g
            .attr("transform", `translate(0,${y(0)})`) // Update here for x-axis at y=0
            .call(d3.axisBottom(x).ticks(width / 80).tickSizeOuter(0))

        const yAxis = g => g
            .attr("transform", `translate(${x(0)},0)`) // Update here for y-axis at x=0
            .call(d3.axisLeft(y))


        const tip = d3Tip()
            .attr('class', 'd3-tip')
            .offset([-10, 0])
            .html((event, d) => {
                return `<span>${d.name}</span>`;
            });

        svg.call(tip);

        const sizeScale = d3.scaleLog().domain([1, 1000000001]).range([2, 10]);

        svg.append("g")
            .selectAll("dot")
            .data(data)
            .enter()
            .append("circle")
            .attr("cx", d => x(d.x))
            .attr("cy", d => y(d.y))
            .attr("r", function (d) {
                return sizeScale(d.follows_count + 1);
            })
            .attr("fill", function (d) {
                const index = selectedUserIds.indexOf(d.user_id);
                if (index !== -1) {
                    const t = selectedUserIds.length > 1 ? 1 - (index / (selectedUserIds.length - 1)) : 0;
                    return colorInterpolator(t);
                } else {
                    return COLOR_END;  // Default color for non-highlighted dots
                }
            })
            .attr("stroke", function (d) {
                const index = selectedUserIds.indexOf(d.user_id);
                if (index !== -1) {
                    const t = selectedUserIds.length > 1 ? 1 - (index / (selectedUserIds.length - 1)) : 0;
                    return strokeColorInterpolator(t);
                } else {
                    return STROKE_COLOR_END;  // Default color for non-highlighted dots
                }
            })
            .attr("stroke-width", function (d) {
                const index = selectedUserIds.indexOf(d.user_id);
                if (index !== -1) {
                    const t = selectedUserIds.length > 1 ? 1 - (index / (selectedUserIds.length - 1)) : 0;
                    return t == 0 ? "5px" : "1px";
                } else {
                    return "1px";
                }
            })
            .on('mouseover', tip.show)
            .on('mouseout', tip.hide)
            .on("click", (event, d) => {
                handleUserClick(d.user_id, "usermap");
                tip.hide();
                const tweetElement = document.getElementById(`tweet-${d.user_id}`);
                if (tweetElement) {
                    const container = document.querySelector('.tweet-list');
                    container.scrollTop = tweetElement.offsetTop - container.offsetTop;
                }
            });

        svg.append("g").call(xAxis);
        svg.append("g").call(yAxis);
    }, [users, selectedUserIds, dimensions]);

    return (
        <div ref={containerRef}>
            <svg ref={svgRef} width={dimensions.width} height={dimensions.height}></svg>
        </div>
    );
}

export default UserScatterPlot;
