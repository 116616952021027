import React, { useRef, useEffect, useState } from 'react';
import * as d3 from 'd3';
import d3Tip from 'd3-tip';
import { interpolate } from 'd3-interpolate';
import { COLOR_START, COLOR_END, STROKE_COLOR_START, STROKE_COLOR_END } from './Colors';

const UserMatrix = ({ data, handleUserClick, selectedUserIds }) => {
    const svgRef = useRef();
    const containerRef = useRef(null);
    const colorInterpolator = interpolate(COLOR_START, COLOR_END);
    const strokeColorInterpolator = interpolate(STROKE_COLOR_START, STROKE_COLOR_END);

    // コンポーネントのサイズに基づいてwidthとheightの初期状態を設定
    const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

    const updateDimensions = () => {
        if (containerRef.current) {
            const width = containerRef.current.offsetWidth;
            const height = containerRef.current.offsetHeight;
            setDimensions({ width, height });
        }
    };

    useEffect(() => {
        updateDimensions(); // 初期サイズを設定
        window.addEventListener("resize", updateDimensions); // ウィンドウのリサイズをリッスン
        return () => {
            window.removeEventListener("resize", updateDimensions); // クリーンアップ
        };
    }, []);

    useEffect(() => {
        if (!data || !data.users || data.users.length === 0) {
            return;
        }

        const margin = { top: 20, right: 48, bottom: 20, left: 20 };
        const size = Math.min(dimensions.width, window.innerHeight - 240);
        const width = size - margin.left - margin.right;

        const cellSize = (width) / 3;

        const svg = d3.select(svgRef.current)
            .attr("width", size)
            .attr("height", size);

        svg.selectAll("*").remove();

        // カテゴリごとにユーザをグループ化
        const groupedByCategory = data.users.reduce((acc, user) => {
            if (!acc[user.category]) {
                acc[user.category] = [];
            }
            acc[user.category].push(user);
            return acc;
        }, {});

        function wrapTextWithDelimiter(selection, width, delimiter = " & ") {
            selection.each(function () {
                let text = d3.select(this),
                    lines = text.text().split(delimiter).map((d, i, arr) => i !== arr.length - 1 ? d + delimiter : d),
                    lineNumber = 0,
                    lineHeight = 1.1, // ems in terms of the font-size
                    x = parseFloat(text.attr("x")),  // Get the x position from the parent text element
                    dy = parseFloat(text.attr("dy"));

                text.text(null);  // Clear original text

                lines.forEach((line, index) => {
                    text.append("tspan")
                        .attr("x", x)  // Set the x position to the parent text's x position
                        .attr("dy", `${index === 0 ? 0 : lineHeight}em`)  // Adjust the vertical position based on the line number
                        .text(line.trim());
                });
            });
        }
        
        data.categories.forEach((category, i) => {
            const x = i % 3;
            const y = Math.floor(i / 3);

            // カテゴリのセルを作成
            svg.append("rect")
                .attr("x", margin.left + x * cellSize)
                .attr("y", margin.top + y * cellSize)
                .attr("width", cellSize)
                .attr("height", cellSize)
                .attr("stroke", "black")
                .attr("fill", "none");

            const cellCenterX = margin.left + x * cellSize + cellSize / 2;
            const cellCenterY = margin.top + y * cellSize + cellSize / 2;


            svg.append("text")
                .attr("x", cellCenterX)
                .attr("y", cellCenterY)
                .attr("text-anchor", "middle")
                .attr("dominant-baseline", "middle")
                .attr("font-size", "14px")
                .text(category)
                .call(wrapTextWithDelimiter, cellSize);

            // ユーザのドットをカテゴリのセルに追加
            const users = groupedByCategory[category] || [];
            users.forEach((user, j) => {
                const dotX = margin.left + x * cellSize + (j % 4 + 0.5) * (cellSize / 4); // 中央に配置
                const dotY = margin.top + y * cellSize + (Math.floor(j / 4) + 0.5) * (cellSize / 4); // 中央に配置

                const data = {
                    user_id: user.user_id,
                    screen_name: user.screen_name,
                    followers_count: user.followers_count,
                    follows_count: user.follows_count,
                    name: user.name
                };

                const sizeScale = d3.scaleLog().domain([1, 100000000001]).range([2, 10]);

                const tip = d3Tip()
                    .attr('class', 'd3-tip')
                    .offset([-10, 0])
                    .html((event, d) => {
                        return `<span>${d.name}</span>`;
                    });

                svg.call(tip);

                svg.append("circle")
                    .datum(data)
                    .attr("cx", dotX)
                    .attr("cy", dotY)
                    .attr("r", function (d) {
                        return sizeScale(d.follows_count + 1);
                    })
                    .attr("fill", function (d) {
                        const index = selectedUserIds.indexOf(d.user_id);
                        if (index !== -1) {
                            const t = selectedUserIds.length > 1 ? 1 - (index / (selectedUserIds.length - 1)) : 0;
                            return colorInterpolator(t);
                        } else {
                            return COLOR_END;  // Default color for non-highlighted dots
                        }
                    })
                    .attr("stroke", function (d) {
                        const index = selectedUserIds.indexOf(d.user_id);
                        if (index !== -1) {
                            const t = selectedUserIds.length > 1 ? 1 - (index / (selectedUserIds.length - 1)) : 0;
                            return strokeColorInterpolator(t);
                        } else {
                            return STROKE_COLOR_END;  // Default color for non-highlighted dots
                        }
                    })
                    .attr("stroke-width", function (d) {
                        const index = selectedUserIds.indexOf(d.user_id);
                        if (index !== -1) {
                            const t = selectedUserIds.length > 1 ? 1 - (index / (selectedUserIds.length - 1)) : 0;
                            return t == 0 ? "5px" : "1px";
                        } else {
                            return "1px";
                        }
                    })
                    .on('mouseover', tip.show)
                    .on('mouseout', tip.hide)
                    .on("click", (event, d) => {
                        handleUserClick(d.user_id, "usermatrix");
                        tip.hide();
                        const tweetElement = document.getElementById(`tweet-${d.user_id}`);
                        if (tweetElement) {
                            const container = document.querySelector('.tweet-list');
                            container.scrollTop = tweetElement.offsetTop - container.offsetTop;
                        }
                    });

            });
        });

    }, [data, selectedUserIds, dimensions]);

    return (
        <div ref={containerRef}>
            <svg ref={svgRef} width={dimensions.width} height={dimensions.height}></svg>
        </div>
    );
}

export default UserMatrix;
