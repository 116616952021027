import React, { useState, useEffect } from 'react';
import TrendList from './TrendList';
import Tweet from "./Tweet";
import ScatterPlot from './ScatterPlot';
import TextBlobScatterPlot from './TextBlobScatterPlot';
import GptScatterPlot from './GptScatterPlot';
import { Container, LeftContainer, RightContainer, Tab, TweetList, Input, Button, SwappedLeftContainer, SwappedRightContainer } from './CommonStyles';
import { apiUrl } from './Config';

function Topics({ setCurrentPage, isGraphVisible, SetGraphVisible, swap, logout }) {
    const [keyword, setKeyword] = useState("");
    const [trends, setTrends] = useState([]);
    const [tweets, setTweets] = useState([]);
    const [plotData, setPlotData] = useState([]);
    const [activeTab, setActiveTab] = useState("gpt");
    const [highlightedTweetIds, setHighlightedTweetIds] = useState([]);
    const [selectedTrendId, setSelectedTrendId] = useState(null);

    const token = localStorage.getItem('jwt_token');


    const fetchTrends = async (keyword) => {
        try {
            const response = await fetch(`${apiUrl}/api/trends`,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`  // Set the JWT token here
                    }

                }
            );

            if (!response.ok) {
                throw new Error(response.statusText);
            }

            const data = await response.json();
            setTrends(data.trends);

            if (keyword != undefined) {
                const matchedTrend = data.trends.find(t => t.trend === keyword);
                if (matchedTrend) {
                    setSelectedTrendId(matchedTrend.id);
                }
            }
        }
        catch (error) {
            logout();
        }
    };

    const updateTweetsAndPlotData = (tweets) => {
        setTweets(tweets);
        setPlotData(tweets.map(tweet => ({
            id: tweet.id,
            score: tweet.nlp_score,
            magnitude: tweet.nlp_magnitude,
            polarity: tweet.textblob_polarity,
            subjectivity: tweet.textblob_subjectivity,
            impression_count: tweet.impression_count,
            gpt_polarity: tweet.gpt_polarity,
            gpt_subjectivity: tweet.gpt_subjectivity
        })));
    }

    const onTrendClick = async (trendId) => {
        try {
            const response = await fetch(`${apiUrl}/api/tweets/${trendId}`,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`  // Set the JWT token here
                    }

                }
            );
            if (!response.ok) {
                throw new Error(response.statusText);
            }

            const data = await response.json();
            updateTweetsAndPlotData(data.tweets);

            setSelectedTrendId(trendId);

            const found_trend = trends.find(tened => tened.id === trendId);

            // イベントデータを作成
            const event = {
                timestamp: new Date().toISOString(),
                type: "trend_click",
                where: "trend_list",
                id: trendId,
                trend: found_trend.trend,
            };

            // 既存のイベントデータを取得
            const existingEvents = JSON.parse(localStorage.getItem("events") || "[]");
            // 新しいイベントを追加
            existingEvents.push(event);
            // 更新されたデータをローカルストレージに保存
            localStorage.setItem("events", JSON.stringify(existingEvents));
        }
        catch (error) {

        }
    }

    const searchTweets = async () => {
        const encodedKeyword = encodeURIComponent(keyword);
        try {
            const response = await fetch(`${apiUrl}/api/search_tweets/?keyword=${encodedKeyword}`,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`  // Set the JWT token here
                    }

                });

            if (!response.ok) {
                throw new Error(response.statusText);
            }

            const data = await response.json();

            // イベントデータを作成
            const event = {
                timestamp: new Date().toISOString(),
                type: "search_trend",
                where: "search_box",
                id: "",
                trend: keyword,
            };

            // 既存のイベントデータを取得
            const existingEvents = JSON.parse(localStorage.getItem("events") || "[]");
            // 新しいイベントを追加
            existingEvents.push(event);
            // 更新されたデータをローカルストレージに保存
            localStorage.setItem("events", JSON.stringify(existingEvents));

            // After updating tweets, also update the trends
            fetchTrends(keyword);

            updateTweetsAndPlotData(data.tweets);
        }
        catch (error) {

        }
    }

    const handleTweetClick = (id, where) => {
        setHighlightedTweetIds(prevIds => {
            if (prevIds.includes(id)) {
                // 既にIDが存在する場合、そのIDを削除して、配列の最後に追加
                return [...prevIds.filter(existingId => existingId !== id), id];
            }
            if (prevIds.length >= 5) {
                // 配列が5つの要素を超える場合は、最も古いIDを削除して新しいIDを追加
                return [...prevIds.slice(1), id];
            }
            return [...prevIds, id]; // 通常のID追加
        });

        const found_tweet = tweets.find(tweet => tweet.id === id);

        // イベントデータを作成
        const event = {
            timestamp: new Date().toISOString(),
            type: "tweet_click",
            where: where,
            id: id,
            nlp_magnitude: found_tweet.nlp_magnitude,
            nlp_score: found_tweet.nlp_score,
            textblob_polarity: found_tweet.textblob_polarity,
            textblob_subjectivity: found_tweet.textblob_subjectivity,
            gpt_polarity: found_tweet.gpt_polarity,
            gpt_subjectivity: found_tweet.gpt_subjectivity,
        };

        // 既存のイベントデータを取得
        const existingEvents = JSON.parse(localStorage.getItem("events") || "[]");
        // 新しいイベントを追加
        existingEvents.push(event);
        // 更新されたデータをローカルストレージに保存
        localStorage.setItem("events", JSON.stringify(existingEvents));
    };

    // Fetch trends when the app loads
    useEffect(() => {
        fetchTrends();
    }, []);

    useEffect(() => {
        if (window.twttr) {
            window.twttr.widgets.load();
        }
    }, [tweets]);

    return (
        <div>
            <h1>Topics / <button onClick={() => setCurrentPage('users')} className='light-link light-link-button'>Users</button></h1>
            <Container>
                {swap ? (
                    <>
                        <SwappedRightContainer style={{ display: isGraphVisible ? 'block' : 'none' }}>
                            <Tab active={activeTab === "textblob"} onClick={() => setActiveTab("textblob")}>
                                TextBlob
                            </Tab>
                            <Tab active={activeTab === "scatter"} onClick={() => setActiveTab("scatter")}>
                                NLP
                            </Tab>
                            <Tab active={activeTab === "gpt"} onClick={() => setActiveTab("gpt")}>
                                GPT
                            </Tab>
                            {activeTab === "scatter" && <ScatterPlot data={plotData} highlightedTweetIds={highlightedTweetIds} onPointClick={handleTweetClick} />}
                            {activeTab === "textblob" && <TextBlobScatterPlot data={plotData} highlightedTweetIds={highlightedTweetIds} onPointClick={handleTweetClick} />}
                            {activeTab === "gpt" && <GptScatterPlot data={plotData} highlightedTweetIds={highlightedTweetIds} onPointClick={handleTweetClick} />}
                        </SwappedRightContainer>
                        <SwappedLeftContainer>
                            <div style={{ margin: "8px" }}>
                                <Input type="text" value={keyword} onChange={event => setKeyword(event.target.value)} />
                                <Button onClick={searchTweets}>Search topic</Button>
                            </div>
                            <TrendList trends={trends} onTrendClick={onTrendClick} selectedTrendId={selectedTrendId} />
                            <TweetList>
                                {tweets.map((tweet) => (
                                    <li key={tweet.id}>
                                        <Tweet tweet={tweet} highlightedTweetIds={highlightedTweetIds} onClick={handleTweetClick} />
                                    </li>
                                ))}
                            </TweetList>
                        </SwappedLeftContainer>
                    </>
                ) : (
                    <>
                        <LeftContainer>
                            <div style={{ margin: "8px" }}>
                                <Input type="text" value={keyword} onChange={event => setKeyword(event.target.value)} />
                                <Button onClick={searchTweets}>Search topic</Button>
                            </div>
                            <TrendList trends={trends} onTrendClick={onTrendClick} selectedTrendId={selectedTrendId} />
                            <TweetList>
                                {tweets.map((tweet) => (
                                    <li key={tweet.id}>
                                        <Tweet tweet={tweet} highlightedTweetIds={highlightedTweetIds} onClick={handleTweetClick} />
                                    </li>
                                ))}
                            </TweetList>
                        </LeftContainer>
                        <RightContainer style={{ display: isGraphVisible ? 'block' : 'none' }}>
                            <Tab active={activeTab === "gpt"} onClick={() => setActiveTab("gpt")}>
                                GPT
                            </Tab>
                            <Tab active={activeTab === "textblob"} onClick={() => setActiveTab("textblob")}>
                                TextBlob
                            </Tab>
                            <Tab active={activeTab === "scatter"} onClick={() => setActiveTab("scatter")}>
                                NLP
                            </Tab>
                            {activeTab === "scatter" && <ScatterPlot data={plotData} highlightedTweetIds={highlightedTweetIds} onPointClick={handleTweetClick} />}
                            {activeTab === "textblob" && <TextBlobScatterPlot data={plotData} highlightedTweetIds={highlightedTweetIds} onPointClick={handleTweetClick} />}
                            {activeTab === "gpt" && <GptScatterPlot data={plotData} highlightedTweetIds={highlightedTweetIds} onPointClick={handleTweetClick} />}
                        </RightContainer>
                    </>
                )}
            </Container>
        </div>
    );
}

export default Topics;