import React, { useState, useEffect } from 'react';
import Users from './components/Users';  // Import the new Users component
import Topics from './components/Topics';  // Import the new Topics component
import Login from './components/Login';  // Import the new Login component
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';  // Update for v6
import './App.css';
import { Container } from './components/CommonStyles';
import { Footer } from './components/CommonStyles';

function App() {
  const [currentPage, setCurrentPage] = useState('home');
  const [isGraphVisible, SetGraphVisible] = useState(true);
  const [swap, setSwap] = useState(false);

  const token = localStorage.getItem('jwt_token');
  const initialLoginState = token ? true : false;
  const [isLoggedIn, setIsLoggedIn] = useState(initialLoginState);

  function exportEventsToCSV() {
    const events = JSON.parse(localStorage.getItem("events") || "[]");

    const header = "event,timestamp,where,id,";
    const additionalHeaders = {
      tweet_click: "nlp_magnitude,nlp_score,textblob_polarity,textblob_subjectivity",
      user_click: "negative,subjective",
      trend_click: "trend"
    };

    const csvRows = [];
    csvRows.push(header + additionalHeaders.tweet_click + "," + additionalHeaders.user_click + "," + additionalHeaders.trend_click);  // Add headers

    events.forEach(event => {
      let row = `${event.type},${event.timestamp},${event.where},${event.id},`;

      if (event.type === "tweet_click") {
        row += `${event.nlp_magnitude},${event.nlp_score},${event.textblob_polarity},${event.textblob_subjectivity},,`;
      } else if (event.type === "user_click") {
        row += `,,,,${event.negative},${event.subjective},`;
      } else if (event.type === "trend_click") {
        row += `,,,,,,${event.trend}`;
      } else if (event.type === "search_trend") {
        row += `,,,,,,${event.trend}`;
      }

      csvRows.push(row);
    });

    const csvContent = csvRows.join("\n");
    const blob = new Blob([csvContent], { type: "text/csv" });
    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.download = "events.csv";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    // エクスポート後、ローカルストレージからイベントデータを削除
    localStorage.removeItem("events");
  }

  const handleLoginSuccess = () => {
    setIsLoggedIn(true);
  };

  const handleLogout = () => {
    localStorage.removeItem('jwt_token');  // Remove the JWT token from local storage
    setIsLoggedIn(false);  // Set the isLoggedIn state to false
  };

  let content;
  if (isLoggedIn) {
    switch (currentPage) {
      case 'users':
        content = <Users setCurrentPage={setCurrentPage} isGraphVisible={isGraphVisible} SetGraphVisible={SetGraphVisible} swap={swap} logout={handleLogout} />;
        break;
      case 'topics':
        content = <Topics setCurrentPage={setCurrentPage} isGraphVisible={isGraphVisible} SetGraphVisible={SetGraphVisible} swap={swap} logout={handleLogout} />;
        break;
      default:
        content = (
          <>
            <h1>Welcome to SerendipitySeeker</h1>
            <ul style={{ listStyleType: 'none', fontSize: '24px' }}>
              <li><button onClick={() => setCurrentPage('topics')} className='light-link-button'>Topics</button></li>
              <li><button onClick={() => setCurrentPage('users')} className='light-link-button'>Users</button></li>
            </ul>
          </>
        );
    }
  } else {
    content = <Login onLoginSuccess={handleLoginSuccess} />;
  }

  return (
    <Container>
      {content}
      {isLoggedIn && (
      <Footer>
        <button className='footer-button' onClick={exportEventsToCSV}>Export Events to CSV</button>
        <button className='footer-button' onClick={() => SetGraphVisible(!isGraphVisible)}>
          {isGraphVisible ? "Graph: Visible" : "Graph: Invisible"}
        </button>
        <button className='footer-button' onClick={() => setSwap(!swap)}>Swap Container</button>
        <button className='footer-button' onClick={handleLogout} style={{ marginLeft: 'auto' }}>Logout</button> {/* Logout button added */}
      </Footer>
      )}
    </Container>
  );
}

export default App;
