import React, { useRef, useEffect } from "react";
import { interpolate } from 'd3-interpolate';
import { COLOR_START, COLOR_END, STROKE_COLOR_START, STROKE_COLOR_END } from './Colors';
import { apiUrl } from './Config';

function Tweet({ tweet, onClick, highlightedTweetIds }) {
  const timeoutId = useRef(null);
  const token = localStorage.getItem('jwt_token');
  const fetchOEmbed = async () => {
    const hostname = window.location.hostname;
    const url = `${apiUrl}/api/oembed?url=https://twitter.com/${tweet.username}/status/${tweet.id}`;
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`${response.status} ${response.statusText}`);
      }

      const data = await response.json();
      return data.html;
    }
    catch (error) {
      console.error(error);
    }
  };

  const [html, setHtml] = React.useState("");

  const colorInterpolator = interpolate(COLOR_START, COLOR_END);

  const handleMouseOver = () => {
    if (timeoutId.current) {
      clearTimeout(timeoutId.current);
    }
    timeoutId.current = setTimeout(() => {
      onClick(tweet.id, "timeline");
    }, 1000);
  };

  const handleMouseOut = () => {
    if (timeoutId.current) {
      clearTimeout(timeoutId.current);
    }
  };

  React.useEffect(() => {
    fetchOEmbed().then((html) => {
      setHtml(html);

      // Twitterのwidgetスクリプトを動的にロード
      const script = document.createElement('script');
      script.src = "https://platform.twitter.com/widgets.js";
      script.async = true;
      document.body.appendChild(script);
    });
  }, []);

  const index = highlightedTweetIds.indexOf(tweet.id);
  let backgroundColor = "transparent"; // Default color set to transparent
  if (index !== -1) {
    const t = highlightedTweetIds.length > 1 ? 1 - (index / (highlightedTweetIds.length - 1)) : 0;
    backgroundColor = colorInterpolator(t);
  }

  return (
    <div id={`tweet-${tweet.id}`} className={`tweet`}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
    >
      <div style={{ display: 'inline-block', padding: '5px', backgroundColor: backgroundColor }}>
        <div
          dangerouslySetInnerHTML={{ __html: html }}
        />
      </div>
    </div>
  );
}

export default Tweet;
